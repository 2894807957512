import feather from 'feather-icons';

import * as bootstrap from "bootstrap";
import './module/core/tiny-slider'
// import '../module/core/feather.min'
import './module/core/plugins.init'
import './module/core/app'
import docReady from '../common/doc_ready';

function toggleMenu() {
  // @ts-ignore
  document.getElementById('isToggle').classList.toggle('open');
  var isOpen = document.getElementById('navigation')
  // @ts-ignore
  if (isOpen.style.display === "block") {
    // @ts-ignore
      isOpen.style.display = "none";
  } else {
    // @ts-ignore
      isOpen.style.display = "block";
  }
};

function initPopover() {
  // tooltip
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // popover
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
}

// ----------- header_banner -----------
const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

// const headerBanner = () => {
//   const name = 'header_banner_shopify';
//   const alertNode = document.querySelector('.header-banner');
//   console.log(getCookie(name));
//   if (!getCookie(name)) {
//     alertNode.style.display = 'block';
//   }
//   alertNode.addEventListener('closed.bs.alert', () => {
//     setCookie(name, Date.now());
//   });
// };
// -------------------------------------

const main = () => {
  feather.replace();
  initPopover();

  const toggle = document.querySelector('#isToggle');
  if (toggle) {
    toggle.addEventListener('click', () => {
      toggleMenu();
    });
  }
};

docReady(() => {
  main();
});

